import styles from "./Stars.module.scss";

/** n星アイコン */
export const Stars = (props: { rank: number }): JSX.Element => {
  const renderStars = (rank: number) => {
    const MAX_RANK = 3;
    const stars = [];

    for (let i = 0; i < MAX_RANK; i++) {
      if (i < rank) {
        stars.push(<span className={`${styles.starSvg} ${styles.active}`} key={i}></span>);
      } else {
        stars.push(<span className={`${styles.starSvg} ${styles.inActive}`} key={i}></span>);
      }
    }

    return stars;
  };

  return <>{renderStars(props.rank)}</>;
};
