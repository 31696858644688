import styles from "./InitialCostScore.module.scss";
import { Stars } from "@/common/icons/Stars/Stars";

/** 初期トクアイコン */
export const InitialCostScore = (props: { rank: number }): JSX.Element => {
  return (
    <div className={styles.container}>
      <span className={styles.label}>初期トク</span>
      <div className={styles.starsContainer}>
        <Stars rank={props.rank}></Stars>
      </div>
    </div>
  );
};
