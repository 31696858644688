import React from "react";

import styles from "./FavoritePictButton.module.scss";
import FavoriteToggle from "@/decorator/FavoriteToggle";

type FavoritePictButtonProps = {
  roomId: number;
  roomUrl: string;
  roomTitle: string;
  roomNumber: string;
  roomArea: string;
  size?: "small" | "middle" | "large";
  hasBorder?: boolean;
  className?: string;
};

export const FavoritePictButton = ({
  roomId,
  roomUrl,
  roomTitle,
  roomNumber,
  roomArea,
  size,
  hasBorder,
  className,
}: FavoritePictButtonProps): JSX.Element => {
  const getClassName = (option: string | undefined, defaultOption: string): string => {
    return option && option in styles ? styles[option] : styles[defaultOption];
  };
  const sizeClass = getClassName(size, "small");
  const borderClass = hasBorder ? styles.border : "";

  const activeHeartSvg = (
    <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.26882 1C7.73891 1 9.08745 1.54339 10.0633 2.51754C10.0795 2.54124 10.097 2.56473 10.1158 2.58791C10.2839 2.79477 10.4875 2.88615 10.6147 2.92675C10.7384 2.96619 10.844 2.9751 10.8929 2.97822C10.9408 2.98127 10.9854 2.98117 10.9978 2.98114L11 2.98113C11.0047 2.98113 11.0215 2.98117 11.0388 2.98084C11.0567 2.9805 11.09 2.97957 11.1304 2.9757C11.2237 2.96676 11.3516 2.94269 11.4908 2.87609C11.6144 2.81695 11.6994 2.74631 11.7358 2.71491C11.7667 2.68829 11.797 2.65915 11.8074 2.6492L11.8097 2.64696C11.8212 2.63598 11.8324 2.62472 11.8433 2.61321C12.8256 1.57904 14.2136 1 15.7312 1C18.7036 1 21 3.30411 21 6C21 9.78217 17.9487 13.4866 11.055 16.9167C4.08416 13.5268 1 9.80177 1 6C1 3.2595 3.34296 1 6.26882 1Z"
        fill="#FF6C6C"
        stroke="#FF6C6C"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const inactiveHeartSvg = (
    <svg width="22" height="18" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.26882 1.25C7.66883 1.25 8.94604 1.76405 9.87114 2.679C9.88707 2.70138 9.90394 2.72359 9.92181 2.74557C10.1303 3.00216 10.3825 3.11506 10.5388 3.16492C10.6905 3.21333 10.8194 3.22404 10.877 3.22771C10.9338 3.23134 10.9864 3.23117 10.9982 3.23114L11 3.23113C11.004 3.23113 11.0234 3.23118 11.0436 3.23079C11.0645 3.23039 11.1049 3.22928 11.1542 3.22456C11.2686 3.21361 11.4266 3.18397 11.5987 3.10159C11.7513 3.02856 11.8557 2.94158 11.899 2.90433C11.9358 2.87263 11.9714 2.83831 11.9808 2.82929L11.9826 2.82756C11.9969 2.81384 12.0109 2.79977 12.0246 2.78538C12.9568 1.80398 14.2778 1.25 15.7312 1.25C18.5744 1.25 20.75 3.45089 20.75 6C20.75 9.61023 17.8515 13.2324 11.0537 16.6379C4.17944 13.2715 1.25 9.62858 1.25 6C1.25 3.40929 3.46902 1.25 6.26882 1.25Z"
        stroke="#969696"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const cls = `${styles.favoriteButton} ${sizeClass} ${borderClass}`;
  const activeEl = <button className={cls}>{activeHeartSvg}</button>;
  const inactiveEl = <button className={cls}>{inactiveHeartSvg}</button>;

  return (
    <FavoriteToggle
      roomId={roomId}
      roomUrl={roomUrl}
      roomTitle={roomTitle}
      roomNumber={roomNumber}
      roomArea={roomArea}
      activeElement={activeEl}
      inactiveElement={inactiveEl}
      className={className}
    ></FavoriteToggle>
  );
};
