import styles from "./UpdatePoint.module.scss";

const UpdatePoint: React.FunctionComponent = () => {
  return (
    <>
      <span className={`${styles.updatePoint}`}></span>
    </>
  );
};

export default UpdatePoint;
